<template>
    <v-container  class="pa-0">
      <v-row justify="center" class="ma-0">
        <v-col cols="12" xl="10" class="pa-4">
          <v-card class="modern-card">
            <!-- Header -->
            <v-card-title class="text-center d-block pt-8 pb-6">
              <h1 class="text-h4 font-weight-bold primary--text">קופה רושמת</h1>
            </v-card-title>
  
            <v-row class="px-6">
              <!-- Left Column -->
              <v-col cols="12" md="6">
                <!-- Customer Info Card -->
                <v-card class="mb-6 rounded-lg" outlined>
                  <v-card-text>
                    <div class="text-h6 font-weight-medium mb-4">פרטי לקוח</div>
                    <div class="text-subtitle-1">
                      <div>משפחת {{ customer.family }}</div>
                      <div>{{ customer.address }} {{ customer.apartment }}</div>
                      <div>{{ customer.city }}</div>
                    </div>
                  </v-card-text>
                </v-card>
  
                <!-- Remaining Items Card -->
                <v-card class="mb-6 rounded-lg success lighten-5" outlined>
                  <v-card-text class="d-flex justify-space-between align-center">
                    <span class="text-h6">זכאי לעוד:</span>
                    <span class="text-h5 success--text font-weight-bold">
                      {{ productCountPermit }} נעליים
                    </span>
                  </v-card-text>
                </v-card>
  
                <!-- Shopping Cart -->
                <v-card class="mb-6 rounded-lg" outlined>
                  <v-card-title class="primary white--text">
                    <v-icon left color="white">mdi-cart</v-icon>
                    פירוט הקנייה
                  </v-card-title>
                  <v-data-table
                    :headers="cartHeaders"
                    :items="customerCart"
                    :items-per-page="-1"
                    hide-default-footer
                    class="elevation-0"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon small color="error" @click="removeFromCart(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:no-data>
                      <div class="pa-6 text-center">
                        <v-icon size="64" color="grey lighten-1">mdi-cart-outline</v-icon>
                        <div class="text-h6 grey--text text--darken-1 mt-2">העגלה ריקה</div>
                      </div>
                    </template>
                  </v-data-table>
                  <v-divider></v-divider>
                  <v-card-text class="text-right">
                    <div class="text-h6">
                      סה"כ לתשלום:
                      <span class="success--text font-weight-bold">₪{{ amountToPay }}</span>
                    </div>
                  </v-card-text>
                </v-card>
  
                <!-- Pre-ordered Items -->
                <v-card class="rounded-lg" outlined>
                  <v-card-title class="deep-purple white--text">
                    <v-icon left color="white">mdi-package-variant</v-icon>
                    נעליים שהוזמנו
                  </v-card-title>
                  <v-data-table
                    :headers="orderedHeaders"
                    :items="customerPreOrders"
                    :items-per-page="-1"
                    hide-default-footer
                    class="elevation-0"
                  >
                    <template v-slot:item.status="{ item }">
                      <v-chip
                        :color="item.status === 'paid' ? 'success' : 'warning'"
                        small
                        label
                      >
                        {{ item.status === 'paid' ? 'נרכש' : 'עדיין לא שולם' }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
  
              <!-- Right Column -->
              <v-col cols="12" md="6">
                <!-- Barcode Scanner Section -->
                <v-card class="mb-6 rounded-lg" outlined>
                  <v-card-title class="justify-center">
                    <h2 class="text-h5 deep-purple--text">סרוק נעל לקנייה</h2>
                  </v-card-title>
                  <v-card-text>
                    <v-row justify="center" class="mb-8">
                      <v-col cols="12" sm="8">
                        <v-text-field
                          v-model="barcode"
                          label="ברקוד מוצר"
                          outlined
                          dense
                          clearable
                          autofocus
                          @keydown="handleKeydown"
                          ref="barcodeInput"
                          hide-details
                          class="barcode-input"
                        >
                          <template v-slot:append>
                            <v-icon color="grey">mdi-barcode-scan</v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
  
                <!-- Action Buttons -->
                <v-row class="mb-6">
                  <v-col cols="4">
                    <v-btn
                      block
                      x-large
                      color="primary"
                      elevation="2"
                      height="100"
                      @click="payDialog = true"
                    >
                      <div class="text-center">
                        <v-icon size="36" class="mb-2">mdi-credit-card</v-icon>
                        <div>מעבר לתשלום</div>
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      block
                      x-large
                      color="success"
                      elevation="2"
                      height="100"
                      @click="saveCart"
                    >
                      <div class="text-center">
                        <v-icon size="36" class="mb-2">mdi-content-save</v-icon>
                        <div>שמירת ההזמנה</div>
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      block
                      x-large
                      color="error"
                      elevation="2"
                      height="100"
                      @click="backToKupa1"
                    >
                      <div class="text-center">
                        <v-icon size="36" class="mb-2">mdi-close-circle</v-icon>
                        <div>ביטול קנייה</div>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
  
                <!-- Cashier Info -->
                <v-card outlined class="rounded-lg">
                  <v-card-text class="d-flex align-center">
                    <v-icon color="deep-purple" class="mr-2">mdi-account</v-icon>
                    <span class="text-h6 deep-purple--text">שם קופאי: {{ name }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
  
            <!-- Loading Progress -->
            <v-overlay :value="progressShow" absolute>
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Dialogs -->
      <PayDialog
        v-model="payDialog"
        v-if="payDialog"
        :amountToPay="amountToPay"
        :productInCart="customerCart"
        :Customer="customer"
        :cashierName="name"
        @purchased="purchasedSuccess"
      />
  
      <OldProductDialog
        v-model="oldProductDialog"
        v-if="oldProductDialog"
        :barcode="barcode"
        @setBarcode="setOldBarcode"
      />
  
      <AlertDialog
        v-model="alertDialog"
        v-if="alertDialog"
        :content="alertContent"
      />
  
      <SnackBar
        v-model="snackbar"
        :snacbarText="snacbarText"
        :snackbarColorBt="snackbarColorBt"
      />
    </v-container>
  </template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import PayDialog from '@/components/kupa/dialogs/PayDialog'
import Auth from '@/services/auth.service.js'
import OldProductDialog from '@/components/kupa/dialogs/OldProductDialog'
import AlertDialog from '@/components/kupa/dialogs/AlertDialog'

export default {
    props: {
        customerForKupa: Object,
        customerOrdersForKupa: Array
    },
    components: {
        SnackBar,
        PayDialog,
        OldProductDialog,
        AlertDialog
    },
    data() {
        return {
            progressShow: false,
            alertDialog: false,
            payDialog: false,
            alertDialog: false,
            oldProductDialog: false,
            alertContent: "",
            alertContentObj: null,
            barcode: "",
            customer: {
                order_details: {
                    ordered_quantity: 0,
                    purchased_quantity: 0
                }
            },
            customerPreOrders: [],
            customerCart: [],
            allProducts: [],
            allCustomerOptions: [],
            tempBothGendersInCart: [],
            tempBothTypesInCart: [],
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            name: "",
            cartHeaders: [
                { text: '#', value: "index", sortable: false },
                { text: 'ברקוד', value: 'barcode', sortable: false },
                { text: 'בן/בת', value: 'gender', sortable: false },
                { text: 'סוג', value: 'type', sortable: false },
                { text: 'מידה', value: 'size', sortable: false },
                { text: 'מחיר', value: 'price', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            orderedHeaders: [
                { text: '#', value: 'index', sortable: false },
                { text: 'מידה', value: 'size' },
                { text: 'בן/בת', value: 'gender' },
                { text: 'סוג', value: 'type' },
                { text: 'סטטוס', value: 'status' },
            ],
        };
    },

    computed: {
        productCountPermit() {
            return this.customer.order_details.ordered_quantity - this.customer.order_details.purchased_quantity - this.customerCart.length;
        },
        amountToPay() {
            return this.customerCart.reduce((total, item) => {
                return total + Number(item.price);
            }, 0); // Start the total at 0
        }
    },
    methods: {
        async getAllProducts() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token")
                let api = process.env.VUE_APP_BASE_URL + "/kupa/get_all_products";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400 || res.status === 404) {
                    this.showSnackBar("שגיאה בבדיקת הנתונים", "red");
                } else if (res.status === 200) {
                    this.allProducts = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get products: " + error, "red");
            }
        },
        isValidBarcode(barcode) {
            return String(barcode).length === 8;
        },
        showAlert(message) {
            this.alertContent = message;
            this.alertDialog = true;
        },
        // Loop over all customer pre-orders and combine all allowed options into one array.
        buildAllAllowedOptions() {
            let combinedOptions = [];
            this.customerPreOrders.forEach(preOrder => {
                // Get allowed options for the current pre-order.
                const options = this.getOptionsForPreOrder(preOrder);
                // Append them to the combined options array.
                combinedOptions = combinedOptions.concat(options);
            });
            this.allCustomerOptions = combinedOptions;
        },
        addProductToCart() {
            const product = this.allProducts.find(product => product.barcode === this.barcode);
            console.log(1111111);
            console.log(product);
            console.log(this.customerPreOrders[0]);

            if (!this.isValidBarcode(this.barcode)) {
                this.showAlert("ברקוד לא תקין, חייב להכיל 8 ספרות בדיוק");
                return;
            }

            // Get the current year's last two digits
            const currentYearLastTwo = new Date().getFullYear().toString().slice(-2);
            // Add the new condition to your existing if statement
            if (!product &&
                !this.barcode.startsWith("9100") &&
                !this.barcode.startsWith("9200") &&
                !this.barcode.startsWith("9300") &&
                !this.barcode.startsWith("9400") &&
                this.barcode.substring(4, 6) !== currentYearLastTwo) {
                this.oldProductDialog = true;
                return;
            }

            if (!product) {
                this.showAlert("הפריט המבוקש לא נמצא, נא לבדוק את תקינות הברקוד");
                return;
            }

            if (this.productCountPermit <= 0) {
                this.showAlert("הלקוח אינו זכאי לפריט נוסף");
                return;
            }

            // this part check if customer has this product in pre orders list
            const checkIfProductFeetToOptions = this.allCustomerOptions.filter(option => {
                return product.gender === option.gender && product.type === option.type && product.size === option.size
            });
            /////

            if (checkIfProductFeetToOptions[0]) {
                console.log("found");
            } else {
                console.log("not found");
                this.alertContent = `הפריט אינו תואם לאחד מן ההזמנות שלכם, נא לקחת פריט מתאים`;
                this.alertDialog = true
                return;
            }

            const availablePreOrders = JSON.parse(JSON.stringify(this.customerPreOrders.filter(preOrder => preOrder.status !== "paid")));

            if (availablePreOrders[0]) {
                const productCopy = JSON.parse(JSON.stringify(product)); // Create a deep copy of the product
                productCopy.orderId = availablePreOrders[0]._id;
                this.customerPreOrders.forEach(preOrder => {
                    if (preOrder._id === productCopy.orderId) {
                        preOrder.status = "paid";
                    }
                })

                this.barcode = "";
                console.log(222222);
                console.log(productCopy);
                this.customerCart.push(productCopy);
            } else {
                this.alertContent = `לא נשארו לכם הזמנות זמינות לרכישה`;
                // this.alertContentObj = groupedSizePermit;
                this.alertDialog = true
            }
            console.log(this.customerCart);

        },
        removeFromCart(item) {
            this.customerPreOrders.forEach(preOrder => {
                if (preOrder._id == item.orderId) {
                    preOrder.status = "order_manually";
                }
            })
            const indexInCart = this.customerCart.findIndex(cartItem => cartItem._id === item._id);
            this.customerCart.splice(indexInCart, 1);
        },
        async saveCart() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token")
                let MyJSON = JSON.stringify({ card_id: this.customer._id, status: "came out" });
                let api = process.env.VUE_APP_BASE_URL + "/kupa/save_cart";
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בבדיקת הנתונים", "red");
                } else if (res.status === 200) {
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error save cart: " + error, "red");
            }
        },
        async purchasedSuccess() {
            try {
                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ card_id: this.customer._id, status: "came out" });
                let api = process.env.VUE_APP_BASE_URL + "/knisa/update_arrival_status";
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
            } catch (error) {
                console.log(error);
            }
            this.$emit('backToKupa1')
        },
        setOldBarcode(formatedBarcode) {
            this.barcode = formatedBarcode;
            this.$nextTick(() => {
                this.$refs.barcodeInput.focus();
            });
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        handleKeydown(event) {
            // Allow Enter key even if barcode length is 8
            if (event.key !== "Enter" && this.barcode.length >= 8) {
                event.preventDefault(); // Prevents further input
            } else if (event.key === "Enter") {
                this.addProductToCart();
            }
        },
        backToKupa1() {
            this.$emit('backToKupa1')
        },
        // Helper function for a single pre-order (from the previous example)
        getOptionsForPreOrder(preOrder) {
            // Convert size to a number
            const baseSize = Number(preOrder.size);

            // Allowed sizes: preOrder.size, preOrder.size - 1, and preOrder.size + 1 as strings.
            const sizes = [baseSize, baseSize - 1, baseSize + 1].map(s => s.toString());

            // Determine allowed genders.
            const primaryGender = preOrder.gender;
            let secondaryGender;
            if (primaryGender === "בן" || primaryGender === "בת") {
                secondaryGender = "בן ובת";
            } else {
                secondaryGender = primaryGender;
            }

            // Determine allowed types.
            const primaryType = preOrder.type;
            let secondaryType;
            if (primaryType === "חול" || primaryType === "שבת") {
                secondaryType = "שבת וחול";
            } else {
                secondaryType = primaryType;
            }

            // For each size, generate the 4 options in a fixed order.
            const options = [];
            // The order of sizes: first the pre-order's own size, then size-1, then size+1.
            const sizeOrder = [baseSize, baseSize - 1, baseSize + 1].map(s => s.toString());
            sizeOrder.forEach(size => {
                options.push({ size, gender: primaryGender, type: primaryType });
                options.push({ size, gender: secondaryGender, type: primaryType });
                options.push({ size, gender: secondaryGender, type: secondaryType });
                options.push({ size, gender: primaryGender, type: secondaryType });
            });
            return options;
        },
    },
    mounted() {
        this.name = Auth.getUserName();
        this.customer = JSON.parse(JSON.stringify(this.customerForKupa));
        this.customerPreOrders = JSON.parse(JSON.stringify(this.customerOrdersForKupa));
        this.getAllProducts();
        this.buildAllAllowedOptions();
    },
};
</script>

<style scoped>
.modern-card {
  border-radius: 16px;
  background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
}

.barcode-input {
  transition: all 0.3s ease;
}

.barcode-input:hover {
  transform: translateY(-2px);
}

.v-btn {
  text-transform: none !important;
  letter-spacing: normal !important;
}

.v-data-table >>> .v-data-table__wrapper {
  overflow-x: auto;
}

.v-data-table >>> tbody tr:hover {
  background-color: #f5f5f5 !important;
}

.v-card {
  transition: all 0.3s ease;
}

.v-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1) !important;
}
</style>