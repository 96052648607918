<template>
  <v-container>
    <div style="margin-top: 70px; margin-bottom: 70px;">
      <v-row class="d-flex justify-center">
        <v-col cols='11' lg='9'>
          <v-card style="padding: 1%;">
            <div style="text-align: center; margin-bottom: 40px;">
              <span class="headline">הגדרות שעות חלוקה</span>
            </div>
            <v-row style="margin-bottom: 30px; margin-right: 20px;">
              <v-col cols="12" sm="6" md="4">
                <v-text-field hide-details type="date" v-model="newDateToadd.date" outlined dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="2">
                <v-select :items="stationsOptions" hide-details v-model="newDateToadd.station" outlined dense
                  label="תחנה">
                </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-select :items="dayNumberOptions" hide-details v-model="newDateToadd.position_day" outlined dense
                  label="מספר יום בתחנה">
                </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-select multiple :items="activeHoursOptions" hide-details v-model="newDateToadd.active_hours" outlined
                  dense label="שעות כניסה">
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2" v-if="newDateToadd.date">
                <v-btn small color="#3F51B5" dark @click="addDate()">
                  הוסף תאריך
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-bottom: 30px; margin-right: 20px;">
              <v-col cols="12" sm="6" md="4">
                <v-select :items="dates" :item-text="item => `${item.date} - ${item.station} - ${item.position_day}`"
                  :item-value="'date'" hide-details v-model="selectedDate" outlined dense label="בחר תאריך להגדרה">
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2" v-if="selectedDate">
                <v-btn small color="#3F51B5" dark @click="deleteDate()">
                  מחק תאריך
                </v-btn>
              </v-col>
            </v-row>

            <div style="margin-bottom: 30px; margin-right: 20px;" v-if="selectedDate">
              <v-row style="margin-bottom: 20px;">
                <v-col cols="12">
                  <span style="font-weight: 600; font-size: large;">תורים שהוזמנו לתאריך זה:
                    {{ selectedObject.countThisDate }}</span>
                  <v-btn small icon color="#3F51B5" dark style="max-width: 20px; margin-right: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="exportToExcel()">
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-for="(hour, index) in selectedObject.active_hours" :key="index">
                <!-- Hour Limit Label -->
                <v-col cols="12" sm="4" md="2">
                  <span style="font-weight: 600;">מגבלה שעה {{ hour.name }}:</span>
                </v-col>

                <!-- Hour Limit Input -->
                <v-col cols="12" sm="6" md="2" style="padding-right: 0px !important;">
                  <v-text-field hide-details type="number" v-model="hour.limit" outlined dense
                    hide-spin-buttons></v-text-field>
                </v-col>

                <!-- Customers Label -->
                <v-col cols="12" sm="2" md="2">
                  <span style="font-weight: 600; font-size: medium;">לקוחות</span>
                </v-col>

                <!-- Booked Appointments Count -->
                <v-col cols="12" sm="6" md="2" style="padding-right: 0px !important;">
                  <v-text-field hide-details type="number" v-model="hour.position" outlined dense label="מיקום השעה"
                    hide-spin-buttons></v-text-field>
                </v-col>

                <!-- Booked Appointments Count -->
                <v-col cols="12" sm="6" md="2">
                  <span style="font-weight: 600;">
                    תורים שהוזמנו לשעה זו: {{ hour.count || 0 }}
                  </span>
                </v-col>


              </v-row>

              <v-row style="margin-top: 50px;">
                <v-col cols="12" class="d-flex justify-center">
                  <v-btn medium color="#3F51B5" dark @click="saveChanges()">
                    שמור שינויים
                  </v-btn>
                </v-col>
              </v-row>

            </div>


            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import Utils from '@/util/utils.js'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  components: {
    SnackBar,
  },
  data() {
    return {
      progressShow: false,
      dates: [],
      newDateToadd: {
        position_day: null,
        station: "",
        date: null,
        active_hours: null,
      },
      dayNumberOptions: [1, 2, 3, 4, 5],
      activeHoursOptions: [15, 16, 18, 20],
      stationsOptions: ["ירושלים", "בית שמש", "בני ברק", "מודיעין עילית"],
      selectedObject: null, // This will hold the full selected object
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
    };
  },
  methods: {
    async getDates() {
      try {
        this.newDateToadd = {
          position_day: null,
          station: "",
          date: null,
        },
          this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + "/settings/get_dates";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400 || res.status === 404) {
          this.showSnackBar("תאריך לא נמצא", "red");
        } else if (res.status === 200) {
          this.dates = jsonObject;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error check id: " + error, "red");
      }
    },
    async addDate() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let MyJSON = JSON.stringify({ newDate: this.newDateToadd });

        let api = process.env.VUE_APP_BASE_URL + "/settings/add_date";
        const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400 || res.status === 404) {
          this.showSnackBar("שגיאה בהוספת תאריך", "red");
        } else if (res.status === 200) {
          this.showSnackBar("התאריך נוסף בהצלחה!", "green");
          this.getDates()
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error add date: " + error, "red");
      }
    },
    async saveChanges() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let MyJSON = JSON.stringify({ dateOBj: this.selectedObject });

        let api = process.env.VUE_APP_BASE_URL + "/settings/save_changes";
        const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400 || res.status === 404) {
          this.showSnackBar("שגיאה בשמירת השינויים", "red");
        } else if (res.status === 200) {
          this.showSnackBar("השינויים נשמרו בהצלחה!", "green");
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error add date: " + error, "red");
      }
    },
    async deleteDate() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let MyJSON = JSON.stringify({ dateOBj: this.selectedObject });

        let api = process.env.VUE_APP_BASE_URL + "/settings/delete_date";
        const res = await fetch(api, ApiServices.requestOptions("DELETE", MyJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400 || res.status === 404) {
          this.showSnackBar("שגיאה במחיקת התאריך", "red");
        } else if (res.status === 200) {
          this.showSnackBar("התאריך נמחק בהצלחה!", "green");
          this.dates = this.dates.filter(d => d._id !== this.selectedObject._id);
          this.selectedObject = null;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error add date: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    exportToExcel() {
      // Create a new workbook and a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.selectedObject.fullCustomersDetailsThisDate);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Items');

      // Generate an XLSX file
      XLSX.writeFile(wb, `תורים תאריך ${this.selectedObject.date}.xlsx`);
    },
  },
  mounted() {
    this.getDates();
  },
  computed: {
    selectedDate: {
      get() {
        // When getting the value, return the date of the selectedObject
        return this.selectedObject ? this.selectedObject.date : null;
      },
      set(value) {
        // When setting the value, find and set the full object based on the selected date
        this.selectedObject = this.dates.find(item => item.date === value) || null;
      },
    },
  },
};
</script>

<style scoped>
.v-dialog .v-card {
  background-color: #f4e1f7;
  /* Light purple background */
}

.v-dialog .v-card-title {
  color: #4a148c;
  /* Dark purple text */
}

.v-dialog .v-btn {
  color: #4a148c;
  /* Dark purple text for buttons */
}

.border-text-field {
  border: 1px solid #eee;
  border-radius: 10px;
}

.divider2 {
  height: 1px;
  background-color: #eee;
}
</style>
