<template>
  <div class="print-page">
    <div style="display: flex; justify-content: space-around; align-items: center; margin-bottom: 10px;">
      <div>
        <span>תחנה: {{ item.shop_station }}</span>
      </div>
      <div v-if="allItemsCount">
        <span>סה"כ הזמנות: {{ allItemsCount }}</span>
      </div>
      <div v-if="allItemsCount">
        <span>דף {{ index + 1 }} מתוך {{ allItemsCount }}</span>
      </div>
    </div>
    <div style="display: flex; justify-content: space-around; align-items: center; margin-bottom: 30px;">
      <div>
        <span>תאריך התור: {{ item.day_to_come }}</span>
      </div>
      <div>
        <span>שעת התור: {{ item.hour_to_come }}</span>
      </div>
    </div>

    <div style="display: flex; justify-content: space-around;">
      <div>
        <h3>משפחת {{ item.family }}</h3>
        <h3>{{ item.address }} {{ item.apartment }}</h3>
        <h3>{{ item.city }}</h3>
      </div>
      <div>
        <svg ref="barcode"></svg>
      </div>
    </div>

    <div style="display: flex; justify-content: space-around; margin-top: 30px;">
      <div style="display: flex; flex-direction: column; justify-content: center;">
        <div style="display: flex; justify-content: center;">
          <span style="font-size: 70px; font-weight: 600;">{{ item.gender }}</span>
        </div>
        <div>
          <span style="font-size: 70px; font-weight: 600;">{{ item.type }}</span>
        </div>
      </div>
      <!-- Second Div -->
      <div style="position: relative; left: -120px;">
        <div style="border: 1px solid #888585; padding: 7px;">
          <span style="font-size: 40px;">{{ Number(item.size) - 1 }}</span>
        </div>
        <div style="border: 1px solid #888585; padding: 7px;">
          <span style="font-size: 45px; font-weight: 600;">{{ item.size }}</span>
        </div>
        <div style="border: 1px solid #888585; padding: 7px;">
          <span style="font-size: 40px;">{{ Number(item.size) + 1 }}</span>
        </div>
      </div>
    </div>

    <div v-if="isVip" style="display: flex; justify-content: center; margin-top: 30px;">
      <span style="font-size: 60px;"></b> * * *</span>
    </div>

    <div style="display: flex; justify-content: center; margin-top: 10px;">
      <div style="border: 1px solid rgb(63, 62, 62); padding: 10px;">
        <div style="display: flex; justify-content: center;">
          <span>חלוקת הנעליים מוקדשת לזכות ולעילוי נשמת</span>
        </div>
        <div style="display: flex; justify-content: center;">
          <span>האשה החשובה מרת חיה פיגא בת רבי גדליה הכהן ע"ה</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import JsBarcode from 'jsbarcode';
import Utils from '@/util/utils.js'

export default {
  // props: ['item'],
  props: {
    item: Object,
    isVip: Boolean,
    index: Number,
    allItemsCount: Number
  },
  mounted() {
    this.generateBarcode();
    console.log(this.isVip);
  },
  created() {
    this.item.day_to_come = Utils.getFormatDateWithoutHour(this.item.day_to_come);
    this.item.hour_to_come = this.item.hour_to_come + ":00 עד " + (this.item.hour_to_come + (this.item.hour_to_come == 0 ? 0 : this.item.hour_to_come == 15 ? 1 : 2)) + ":00";
  },
  watch: {
    // Watch for changes to item.father_id and regenerate the barcode
    'item.father_id': function (newVal, oldVal) {
      this.generateBarcode();
    }
  },
  methods: {
    generateBarcode() {
      if (this.item && this.item.father_id) {
        JsBarcode(this.$refs.barcode, this.item.father_id, {
          format: "CODE128",
          lineColor: "#000", // Ensure high contrast
          width: 2, // Consider increasing the width
          height: 40, // Adjust height as needed
          displayValue: true, // Hide the value to prevent interference; adjust based on need
          margin: 10 // Ensure adequate quiet zone around the barcode
        });
      }
    }
  }
}
</script>
<style scoped>
.print-page {
  page-break-after: always !important;
  /* Ensure each component instance begins on a new page */
}

.barcode {
  margin-top: 20px;
  /* Adjust as needed */
}


@media print {

  /* Target elements within your component for print styling */
  .print-container {
    width: 148mm;
    height: 210mm;
    margin: 0 auto;
    /* Center the print content if needed */
    padding: 0;
    /* Additional component-specific print styles */
  }
}
</style>