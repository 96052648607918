<template>
    <v-container>
        <div style="display: flex; justify-content: center; align-items: center; height: 80vh;">
            <v-card
                style="width: 700px; max-width: 100%; padding: 1%; background: linear-gradient(180deg, rgba(219,221,233,1) 1%, rgba(225,196,255,1) 70%); box-shadow: 0 0 10px #fff;">
                <div style="text-align: center; margin-bottom: 70px;">
                    <span class="headline">בדיקה לפני כניסה למכירה</span>
                </div>
                <v-form @submit.prevent="submitHandler" ref="form">
                    <v-row class=" d-flex justify-center" style="margin-bottom: 100px;">
                        <v-col cols="7" background-color="white" class="border-text-field"
                            style="background-color: white; padding: 0px 10px 10px 10px;">
                            <v-text-field v-model="card_id" autofocus label="הזן מספר תעודת זהות"
                                @keydown.enter="handleEnterKey()" :rules="[v => !!v || 'שדה חובה']" required type="number"
                                hide-details hide-spin-buttons flat></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row v-if="progressShow" class="my-5">
                    <v-col class="d-flex justify-center">
                        <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="pink" outlined @click="checkId()">בדיקה</v-btn>
                </v-card-actions>
            </v-card>
        </div>

        <AlertDialog v-model="alertDialog" v-if="alertDialog" :content="content" />
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>
</template>
  
<script>
import AlertDialog from '@/components/beforeKnisa/dialogs/AlertDialog'
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Vue from 'vue';

export default {
    components: {
        AlertDialog,
        SnackBar
    },
    data() {
        return {
            alertDialog: false,
            progressShow: false,
            content: {},
            card_id: '',
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
        };
    },
    methods: {
        async checkId() {

            if (this.$refs.form.validate()) {
                try {

                    this.progressShow = true;
                    let token = localStorage.getItem("token");
                    let MyJSON = JSON.stringify({ card_id: this.card_id });

                    let api = process.env.VUE_APP_BASE_URL + "/knisa/check_card_id";
                    const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                    const jsonObject = await res.json();
                    this.progressShow = false;
                    if (res.status === 400 || res.status === 404) {
                        this.showSnackBar("שגיאה בבדיקת הנתונים", "red");
                    } else if (res.status === 200) {
                        this.content = jsonObject;
                        this.alertDialog = true;
                        this.card_id = "";
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error check id: " + error, "red");
                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        handleEnterKey() {
            this.checkId(); // Trigger the button click event
        },
        printItems() {
            const printContent = document.createElement('div');

            this.itemsToPrint.forEach((item, index) => {
                let ComponentClass = Vue.extend(PrintTemplate);
                let instance = new ComponentClass({
                    propsData: { item }
                });
                instance.$mount(); // Mount the component instance to generate HTML
                instance.$el.classList.add('print-page'); // Ensure the page break class is applied

                // Append the instance to a temporary container
                printContent.appendChild(instance.$el);
            });

            // Open and configure the print window
            const printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
            printWindow.document.write('<html dir="rtl"><head><title></title></head><body>');
            printWindow.document.write('<style>.print-page { page-break-after: always; }</style>'); // Include the style directly
            printWindow.document.body.appendChild(printContent); // Append the container with all items
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            this.$nextTick(() => {
                printWindow.print(); // Invoke the print dialog
                //    printWindow.close(); // Consider removing this line or managing window closing differently.
            });
        },
    },
    mounted() {
    },
};
</script>
  
<style scoped>
.v-dialog .v-card {
    background-color: #f4e1f7;
    /* Light purple background */
}

.v-dialog .v-card-title {
    color: #4a148c;
    /* Dark purple text */
}

.v-dialog .v-btn {
    color: #4a148c;
    /* Dark purple text for buttons */
}

.border-text-field {
    border: 1px solid #eee;
    border-radius: 10px;
}
</style>
  