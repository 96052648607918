<template>
  <v-navigation-drawer v-model="drawer" :mini-variant="isCollapsed" :mini-variant-width="60" width="300" right app
    permanent class="primary-gradient" elevation="4">
    <div @mouseenter="handleMouseEnter">

      <!-- Mini variant menu button -->
      <v-list-item class="px-2 py-1">
        <v-list-item-action>
          <v-btn icon @click="$emit('toggleExpand')">
            <v-icon>{{ isCollapsed ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>

      <!-- User Profile Section -->
      <v-list-item v-if="!isCollapsed" >
        <v-list-item-content>
          <v-list-item-title class="title font-weight-bold primary--text">
            {{ userName }}
          </v-list-item-title>
          <v-list-item-subtitle class="subtitle-2 primary--text">
            {{ email }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon small color="primary" @click="logOut" class="logout-btn">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <!-- Personal Area Button -->
      <div class="text-center my-4" v-if="!isCollapsed">
        <v-btn rounded elevation="2" color="primary" class="px-6" @click="openAccountDetails">
          <v-icon left>mdi-account</v-icon>
          אזור אישי
        </v-btn>
      </div>

      <v-divider class="my-2"></v-divider>

      <!-- Navigation Items -->
      <v-list dense nav class="mt-2 navigation-list">
        <v-list-item v-for="item in tables" :key="item.name" @click="showTable(item.name)" class="mb-5 mx-2 rounded-lg"
          :class="{ 'mini-list-item': isCollapsed }">
          <v-list-item-icon>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title style="font-size: large;">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
  props: {
    tableListArray: Array,
    isCollapsed: Boolean
  },
  components: {
    SnackBar
  },
  data: () => ({
    drawer: true,
    userName: "ffff",
    email: "grgrgegrg",
    tables: []
  }),
  methods: {
    showTable(name) {
      this.$emit("showTable", name);
    },
    getUserDetails() {
      this.userName = Auth.getUserName();
      this.email = Auth.getUserEmail();
    },
    logOut() {
      localStorage.removeItem("token");
      this.$router.replace({ name: "login" })
    },
    openAccountDetails() {
      this.$emit("showAccountDetails");
    },
    handleMouseEnter() {
      console.log(22222);

      if (this.isCollapsed) {
        this.$emit('toggleExpand', false); // Only emit if collapsed
      }
    }
  },
  watch: {
    isCollapsed(val) {
      this.drawer = !val;
    }
  },
  mounted() {
    this.tables = this.tableListArray;

    this.getUserDetails();
  },
}
</script>
<style lang="scss" scoped>
.primary-gradient {
  background: linear-gradient(145deg, #E8EAF6 0%, #C5CAE9 100%);
}

.navigation-list {
  height: calc(100vh - 250px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(63, 81, 181, 0.5);
    border-radius: 10px;

    &:hover {
      background: rgba(63, 81, 181, 0.7);
    }
  }
}

.v-list-item {
  transition: all 0.3s ease;

  &:hover {
    background: rgba(63, 81, 181, 0.1) !important;
    transform: translateX(-4px);
  }
}

.mini-list-item {
  padding-left: 0 !important;
  justify-content: center !important;
}

.logout-btn {
  opacity: 0.8;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
}

.v-btn {
  letter-spacing: 0.5px;
}

// RTL Support
.v-application--is-rtl {
  .v-list-item {
    &:hover {
      transform: translateX(4px);
    }
  }
}
</style>