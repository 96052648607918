<template>
  <div style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='11' xl='11'>
        <v-data-table id="table1" :headers="computedHeaders" :items="customers" :search="search" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" @update:sort-by="sortBy => debouncedGetCustomers()"
          @update:sort-desc="sortDesc => debouncedGetCustomers()" @click:row="openCustomerPage" class="elevation-4"
          :items-per-page="itemsPerPage" @update:items-per-page="onItemsPerPageChange" :page.sync="page"
          :server-items-length="totalCustomers" @update:page="onPageChange"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

          <template v-slot:top>
            <div class="d-flex justify-center pt-3">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #5C6BC0;">לקוחות</span>
            </div>
            <div class="divider"></div>

            <v-card elevation="0">
              <v-card-title>
                <div class="d-flex flex-wrap pa-5 ">
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="openCreateDIalog()">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>הוסף חדש</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="downloadExcel()">
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="importDialog = true">
                          mdi-file-upload
                        </v-icon>
                      </template>
                      <span>ייבוא מאקסל</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="refreshDetails()">
                          mdi-refresh
                        </v-icon>
                      </template>
                      <span>רענן תוצאות</span>
                    </v-tooltip>
                  </v-btn>

                  <v-menu v-model="isMenuOpen" :close-on-content-click="false" offset-y max-height="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small color="#3F51B5" v-bind="attrs" v-on="on" dark class="mb-2"
                        style="margin-right: 50px;">שדות להצגה
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(item, index) in headersList" :key="index" link>
                        <v-list-item-action>
                          <v-switch v-model="item.status" @change="handleStatusChange(item)"></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>


                </div>
                <v-spacer></v-spacer>
                <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details>
                </v-text-field> -->
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details clearable
                  @input="handleSearchInput">
                </v-text-field>
              </v-card-title>
            </v-card>
          </template>

          <template v-slot:item.index="{ index }">
            <tr>
              <!-- Calculate the correct index based on the current page and items per page -->
              <!-- Assuming page is 1-based, adjust calculation if your page numbering starts from 0 -->
              <td>{{ (page - 1) * itemsPerPage + index + 1 }}</td>
            </tr>
          </template>

          <template v-slot:item.status="{ item, index }">
            <tr>
              <td>{{ item.status ? "פעיל" : "מושבת" }}</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn color="#3F51B5" dark style="margin-bottom: 10px;" @click="getCustomers()">
              רענן
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :itemToEdit="null" :formTitle="'הוסף לקוח'"
      @customerAdded="newCustomerSaved" @customerEdited="editedCustomerSaved"> </AddEditDialog>

    <ImportFromExcelDialog v-model="importDialog" v-if="importDialog" @ImportExcel="ImportExcel"
      @exportExampleExcel="donwloadExampleExcel"></ImportFromExcelDialog>

    <ImportFinishDialog v-model="importFinishDialog" v-if="importFinishDialog" :importedSuccess="importedSuccess"
      :importedFailed="importedFailed" @importedSuccessAdded="importedSuccessAdded"></ImportFinishDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <template>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
      </v-overlay>
    </template>
  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import TableToExcel from "@linways/table-to-excel";
import * as XLSX from 'xlsx/xlsx.mjs';
import AddEditDialog from '@/components/customers/dialogs/AddEditDialog'
import AlertDialog from '@/components/customers/dialogs/AlertDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import Utils from "@/util/utils";
import ImportFromExcelDialog from '@/components/customers/dialogs/ImportExcel'
import ImportFinishDialog from '@/components/customers/dialogs/ImportMessageDialog'
import _ from 'lodash'; // Import lodash or use another debounce utility

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar,
    ImportFromExcelDialog,
    ImportFinishDialog
  },
  data: () => ({
    importDialog: false,
    overlay: false,
    importFinishDialog: false,
    importedFailed: [],
    importedSuccess: [],
    progressSave: false,
    formTitle: "",
    headersList: [
      { text: '#', value: "index", status: true, sortable: false },
      { text: 'משפחה', value: 'family', status: true },
      { text: 'אבא', value: 'father_name', status: true },
      { text: 'אמא', value: 'mother_name', status: true },
      { text: 'ת.ז אבא', value: 'father_id', status: true },
      { text: 'ת.ז אמא', value: 'mother_id', status: true },
      { text: 'טלפון 1', value: 'phone1', status: true },
      { text: 'טלפון 2', value: 'phone2', status: true },
      { text: 'טלפון 3', value: 'phone3', status: true },
      { text: 'טלפון 4', value: 'phone4', status: true },
      { text: 'אימייל', value: 'email', status: true },
      { text: 'רחוב', value: 'address', status: true },
      { text: 'עיר', value: 'city', status: true },
      { text: 'מספר בית', value: 'apartment', status: true },
      { text: 'קהילה', value: 'community', status: true },
      { text: 'סטטוס', value: 'status', status: true },
      { text: 'מצב משפחתי', value: 'mode', status: true },
      { text: 'מזהה פאוורלינק', value: 'powerLink_id', status: true },
      { text: 'כמות זכאות להזמנה', value: 'order_details.possible_quantity', status: true },
      { text: 'כמות שהוזמנה', value: 'order_details.ordered_quantity', status: true },
      { text: 'כמות שנרכשה', value: 'order_details.ordered_purchased', status: true },
      { text: 'כמות שהוחלפה', value: 'order_details.ordered_exchanged', status: true },
      { text: 'נקודת חלוקה', value: 'order_details.shop_station', status: true },
      { text: 'יום חלוקה', value: 'order_details.day_to_come', status: true },
      { text: 'שעת חלוקה', value: 'order_details.hour_to_come', status: true },
      { text: 'נוצר בתאריך', value: 'createdAt', status: true },
      { text: 'עודכן בתאריך', value: 'updatedAt', status: true },
    ],
    switchStates: {},
    itemsPerPage: 50,
    alertDialog: false,
    addEditDialog: false,
    customers: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    email: "",
    search: "",
    isMenuOpen: false,
    fieldType: [],
    page: 1, // Current page
    totalCustomers: 0, // Total number of customers for pagination controls
    sortBy: "family",
    sortDesc: false, // default sort direction
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    computedHeaders() {
      return this.headersList.filter(header => header.status);
    },
  },
  methods: {

    async getCustomers() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        this.customers = [];
        const customerJSON = JSON.stringify({ search: this.search, page: this.page, itemsPerPage: this.itemsPerPage, sortBy: this.sortBy, sortDesc: this.sortDesc });
        let api = process.env.VUE_APP_BASE_URL + "/customers/get_customers";
        const res = await fetch(api, ApiServices.requestOptions("POST", customerJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.customers = jsonObject.customers;
          this.totalCustomers = jsonObject.total;
          console.log(this.customers);
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.getCustomers();
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      // No need to call fetchCustomers() if you're using a watcher on itemsPerPage
    },
    async getCustomersHeaders() {
      try {
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/customers/get_customers_headers";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          const headersSaved = jsonObject;

          // Iterate over headersList and update items with matching items from headersSaved
          if (headersSaved[0]) {
            this.headersList = this.headersList.map(header => {
              const foundHeader = headersSaved.find(savedHeader => savedHeader.value === header.value);
              // If a matching header is found in headersSaved, return that, otherwise return the original header
              return foundHeader ? foundHeader : { ...header, status: false };
            });
          }
        }

      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    formatDetails() {
      this.customers = this.customers.map((customer) => {
        // Format the nested 'order_details.day_to_come' property
        const formattedOrderDetails = {
          ...customer.order_details,
          day_to_come: customer.order_details.day_to_come ? Utils.getFormatDateWithoutHour(customer.order_details.day_to_come) : "",
        };
        // Return the new customer object with the formatted 'order_details' and other properties
        return {
          ...customer,
          order_details: formattedOrderDetails, // Use the formatted nested object
          createdAt: Utils.getFormatDate2(customer.createdAt),
          updatedAt: Utils.getFormatDate2(customer.updatedAt),
        };
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async donwloadExampleExcel() {

      const originalHeaders = JSON.parse(JSON.stringify(this.headersList));

      this.headersList = this.headersList.filter(header => header.value !== "order_details.ordered_quantity"
        && header.value !== "index" && header.value !== "createdAt" && header.value !== "updatedAt");

      this.headersList.forEach(header => {
        header.status = true;
      })


      // Create a copy of the headers with English text
      const englishHeaders = [
        // { text: '#', value: "index" },
        { text: 'family', value: 'family' },
        { text: 'father_name', value: 'father_name' },
        { text: 'mother_name', value: 'mother_name' },
        { text: 'father_id', value: 'father_id' },
        { text: 'mother_id', value: 'mother_id' },
        { text: 'phone1', value: 'phone1' },
        { text: 'phone2', value: 'phone2' },
        { text: 'phone3', value: 'phone3' },
        { text: 'phone4', value: 'phone4' },
        { text: 'email', value: 'email' },
        { text: 'address', value: 'address' },
        { text: 'city', value: 'city' },
        { text: 'apartment', value: 'apartment' },
        { text: 'community', value: 'community' },
        { text: 'status', value: 'status' },
        { text: 'mode', value: 'mode' },
        { text: 'powerLink_id', value: 'powerLink_id' },
        { text: 'possible_quantity', value: 'order_details.possible_quantity' },
        // { text: 'ordered_quantity', value: 'order_details.ordered_quantity' },
        { text: 'shop_station', value: 'order_details.shop_station' },
        { text: 'day_to_come', value: 'order_details.day_to_come' },
        { text: 'hour_to_come', value: 'order_details.hour_to_come' },
        // { text: 'createdAt', value: 'createdAt' },
        // { text: 'updatedAt', value: 'updatedAt' },
      ];

      this.$nextTick(() => {
        // Clone the table
        const table = document.getElementById('table1');
        const clonedTable = table.cloneNode(true);

        // // Replace the table headers with the English headers in the cloned table
        const tableHeaders = clonedTable.getElementsByTagName('th');
        for (let i = 0; i < englishHeaders.length; i++) {
          console.log(englishHeaders[i].text);
          console.log(tableHeaders[i].textContent);
          tableHeaders[i].textContent = englishHeaders[i].text;
        }

        // Now the clonedTable only contains the desired header columns
        TableToExcel.convert(clonedTable, {
          name: 'exampleCustomersFile.xlsx',
          sheet: {
            name: 'Sheet 1',
          },
        });

        this.headersList = originalHeaders;

      })
    },
    async downloadExcel() {
      try {
        this.overlay = true;
        let token = localStorage.getItem("token");
        const body = JSON.stringify({ filter: this.search, sortBy: this.sortBy, sortDesc: this.sortDesc });
        let api = `${process.env.VUE_APP_BASE_URL}/customers/export_customers_to_csv`;
        const res = await fetch(api, ApiServices.requestOptions("POST", body, token));
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          // Handle successful response
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `customers.xlsx`; // Suggested file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        this.showSnackBar("Error get items search list: " + error, "red");
      } finally {
        this.overlay = false;
      }
    },
    openCustomerPage(customer) {
      this.$emit("openCustomerDetails", customer._id)
    },
    // Method to set items per page
    // setItemsPerPage(value) {
    //   this.itemsPerPage = value;
    // },
    refreshDetails() {
      this.getCustomers();
    },
    openEditDialog(item, index) {
      this.editedItem = item;
      this.formTitle = "ערוך לקוח"
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemToDelete = item
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף לקוח"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newCustomerSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.customers.push(item);

    },

    customerDeleted(item, text, color) {
      if (item === "") {
        this.showSnackBar(text, color)
        return
      }
      const index = this.customers.findIndex(product => product._id === item._id);
      this.customers.splice(index, 1);
      this.showSnackBar(text, color)
    },
    ImportExcel(file) {

      this.importedSuccess = [];
      this.importedFailed = [];

      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          for (let i = 0; i < jsonData.length; i++) {
            const row = jsonData[i];
            let { family, father_name, mother_name, father_id, mother_id, phone1, phone2, phone3, phone4, email, address, city,
              apartment, community, status, powerLink_id, possible_quantity, shop_station, day_to_come, hour_to_come } = row;

            if (mother_id === undefined) {
              mother_id = ""
            }
            if (father_id === undefined) {
              father_id = ""
            }
            console.log(222222);
            console.log(phone1);
            console.log(typeof (phone1));

            if (phone1 && !phone1.startsWith('0')) {
              phone1 = "0" + phone1;
            }
            if (phone2 && !phone2.startsWith('0')) {
              phone2 = "0" + phone2;
            }
            if (phone3 && !phone3.startsWith('0')) {
              phone3 = "0" + phone3;
            }
            if (phone4 && !phone4.startsWith('0')) {
              phone4 = "0" + phone4;
            }

            // Valid subscribe not empty
            if (!father_id && !mother_id) {
              console.log("error row is missing card_id");
              console.log(row);
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            // if (status && status === "פעיל") {
            //   status = true;
            // } else if (status && status === "מושבת") {
            //   status = false;
            // } else if (status && status === "לא פעיל") {
            //   status = false;
            // } else {
            //   status = true;
            // }

            const regex = /^\d+$/; // Only numeric characters
            const isValidFormat1 = regex.test(possible_quantity);
            if (possible_quantity && !isValidFormat1) {
              console.log("error format possible_quantity");
              console.log(row);
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            const isValidFormat3 = regex.test(hour_to_come);
            if (hour_to_come && !isValidFormat3) {
              console.log("error format hour_to_come");
              console.log(row);
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            const rowData = {
              family, father_name, mother_name, father_id, mother_id, phone1, phone2, phone3, phone4, email, address, city,
              apartment, community, status, powerLink_id, order_details: { possible_quantity, shop_station, day_to_come, hour_to_come }
            };

            this.importedSuccess.push(rowData)
          }

          this.importFinishDialog = true;

        } catch (error) {
          this.showSnackBar('Error parsing Excel file: ' + error, "red");
        }
      };
      reader.onerror = (e) => {
        this.showSnackBar('Error reading file: ' + e.target.error, "red");
      };
      reader.readAsArrayBuffer(file);

    },
    importedSuccessAdded() {
      this.showSnackBar('הלקוחות נוספו בהצלחה!', "green");
      this.getCustomers();
    },
    updateHeadersBasedOnSwitchState() {
      this.headers = this.headersList.filter(header => this.switchStates[header.value]);
    },
    async handleStatusChange(item) {
      try {
        let token = localStorage.getItem("token");
        const headerJSON = JSON.stringify({ header: item });
        let api = process.env.VUE_APP_BASE_URL + "/customers/save_header_changed";
        const res = await fetch(api, ApiServices.requestOptions("POST", headerJSON, token));
      } catch (error) {
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    handleSearchInput() {
      if (this.search) {
        this.debouncedGetSearchCustomers()
      } else {
        this.debouncedGetCustomers()
      }
    },
  },
  watch: {
    itemsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCustomers();
      }
    },
  },
  mounted() {
    this.getCustomers();
    this.getCustomersHeaders();

    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetSearchCustomers = _.debounce(this.getCustomers, 700);
    this.debouncedGetCustomers = _.debounce(this.getCustomers, 300);

  },
}
</script>

<style scoped>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}

::v-deep #table1 .v-data-table__wrapper table tbody tr {
  cursor: pointer;
}
</style>