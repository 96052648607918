import { render, staticRenderFns } from "./CustomerDetails.vue?vue&type=template&id=4eb65d97&scoped=true&"
import script from "./CustomerDetails.vue?vue&type=script&lang=js&"
export * from "./CustomerDetails.vue?vue&type=script&lang=js&"
import style0 from "./CustomerDetails.vue?vue&type=style&index=0&id=4eb65d97&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb65d97",
  null
  
)

export default component.exports