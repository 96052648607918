import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isTablesListExpanded: false,
  },
  mutations: {
    TOGGLE_TABLES_LIST(state) {
      state.isTablesListExpanded = !state.isTablesListExpanded;
    },
    SET_TABLES_LIST_EXPANDED(state, value) {
      state.isTablesListExpanded = value;
    }
  },
});
