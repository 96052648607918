<template>
    <v-dialog v-model="openMode" persistent max-width="700px">
        <v-card style="background-color: #eee;">
            <div>
                <v-tooltip bottom v-if="!creditcardPaid">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="#3F51B5" large @click="openMode = false">
                            mdi-window-close
                        </v-icon>
                    </template>
                    <span>חזור לקופה</span>
                </v-tooltip>
            </div>
            <div style="padding: 30px;">
                <v-row>
                    <v-col cols="12" md="6">
                        <div style="margin-top: 20px;">
                            <span style="font-size: x-large; font-weight: 600;">סה"כ זוגות: </span>
                            <span style="font-size: x-large; font-weight: 600; color: rgb(114, 27, 63);">{{
                                productInCart.length }}
                            </span>
                        </div>

                        <div style="margin-top: 50px;">
                            <span style="font-size: x-large; font-weight: 600;">יתרה בחשבון: </span>
                            <span style="font-size: x-large; font-weight: 600; color: green;">{{
                                customer.order_details.balance }}
                            </span>
                            <span style="font-size: x-large; font-weight: 600;">₪</span>
                        </div>
                        <div style="margin-top: 10px;" v-if="customer.order_details.balance > 0">
                            <v-btn dark :class="{ 'animate-background': customer.order_details.balance > 0 }"
                                @click="applyBalance">לשימוש ביתרה</v-btn>
                        </div>
                        <div style="margin-top: 50px;">
                            <span style="font-size: x-large; font-weight: 600;">לתשלום: </span>
                            <span style="font-size: x-large; font-weight: 600; color: green;">{{ amountToPay }}
                            </span>
                            <span style="font-size: x-large; font-weight: 600;">₪</span>
                        </div>
                        <div style="margin-top: 20px;">
                            <span style="font-size: x-large; font-weight: 600;">שולם: </span>
                            <span style="font-size: x-large; font-weight: 600; color: green;">{{ amountPaid }}
                            </span>
                            <span style="font-size: x-large; font-weight: 600;">₪</span>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div style="margin-top: 20px; display: flex; justify-content: center; margin-bottom: 20px;">
                            <div style="text-align: center;">
                                <span
                                    style="font-size: x-large; font-weight: 600; color: rgb(114, 27, 63); text-align: center;">נא
                                    להקיש את הסכום </span>
                                <span
                                    style="font-size: x-large; font-weight: 600; color: rgb(114, 27, 63); text-align: center;">ששולם
                                    במזומן
                                    וללחוץ על "בצע"</span>
                            </div>
                        </div>

                        <v-row v-for="(row, rowIndex) in keypad" :key="`row-${rowIndex}`">
                            <v-col v-for="(key, keyIndex) in row" :key="`key-${rowIndex}-${keyIndex}`" cols="4"
                                style="border: 1px solid #d1cece; padding: 0px !important;">
                                <v-btn :text="key !== 'מחק' && key !== 'בצע'"
                                    style="padding: 25px !important; font-size: x-large;"
                                    :color="key === 'מחק' ? 'rgba(255, 0, 0, 0.616)' : key === 'בצע' ? 'rgba(0, 128, 0, 0.603)' : 'default'"
                                    block @click="onKeypad(key)">
                                    {{ key === 'מחק' ? 'מחק' : key === 'בצע' ? 'בצע' : key }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class=" d-flex justify-center">
                            <v-col cols="12" md="7">
                                <v-text-field v-model="inputValue" label="סכום"
                                    style="font-size: x-large; text-align: center; margin-top: 10px;" autofocus
                                    type="number" @keydown.enter="onKeypad('בצע')" outlined dense
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class=" d-flex justify-center" style="margin-bottom: 30px; margin-top: 20px;">
                            <v-col cols="auto">
                                <div @click="creditCardDialog = true"
                                    style="background-color: #fff; padding: 5px; border-radius: 20px; margin-left: 12px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img v-on="on" src="@/assets/credit_pay.svg"
                                                style="width: 77px; max-width: 100%; cursor: pointer;">
                                        </template>
                                        <span>תשלום בכרטיס אשראי</span>
                                    </v-tooltip>
                                </div>
                            </v-col>
                            <v-col cols="auto">
                                <div @click="checkDialog = true"
                                    style="background-color: #fff; padding: 10px; border-radius: 20px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img v-on="on" src="@/assets/check_pay.svg"
                                                style="width: 70px; max-width: 100%; cursor: pointer;">
                                        </template>
                                        <span>תשלום בצ'ק</span>
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row style="margin-top: 20px;">
                    <v-col cols="12" style="text-align: center;">
                        <v-btn dark color="rgba(0, 128, 0, 0.603)" @click="purchase" :disabled="purchaseInProgress">סיום רכישה והדפסת קבלה</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="purchaseInProgress" class="my-5">
                    <v-col class="d-flex justify-center">
                        <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <CreditCardDialog v-model="creditCardDialog" v-if="creditCardDialog" :amount="amountToPayInCC" :customer="customer"
            @paySuccess="paySuccess" />

        <CheckDialog v-model="checkDialog" v-if="checkDialog" :amount="amountToPayInCC" @paySuccess="paySuccess" />

        <!-- <InvoiceTemplate v-model="invoicePrint" v-if="invoicePrint" :customer="customer" :productInCart="productInCart" /> -->

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import CreditCardDialog from '@/components/kupa/dialogs/CreditCardDialog'
import CheckDialog from '@/components/kupa/dialogs/CheckDialog'
import InvoiceTemplate from '@/components/kupa/InvoiceTemplate'
import Vue from 'vue';

export default {
    components: {
        SnackBar,
        CreditCardDialog,
        CheckDialog,
        InvoiceTemplate
    },
    props: {
        amountToPay: Number,
        productInCart: Array,
        Customer: Object,
        cashierName: String,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        inputValue: '',
        progressShow: false,
        creditCardDialog: false,
        checkDialog: false,
        snackbar: false,
        invoicePrint: false,
        purchaseInProgress: false,
        customer: {},
        snackbarColorBt: "green",
        snacbarText: "",
        creditcardPaid: false,
        keypad: [
            [1, 2, 3],
            [4, 5, 6],
            [7, 8, 9],
            ['בצע', 0, 'מחק']
        ],
        amountPaid: 0,
        payType: [],
    }),
    methods: {
        onKeypad(key) {
            if (key === 'מחק') {
                this.inputValue = this.inputValue = "";
            } else if (key === 'בצע') {
                if (this.amountPaid + Number(this.inputValue) > this.amountToPay) {
                    return this.showSnackBar("הסכום שהוזן גדול מהסכום לתשלום", "red");
                }
                this.amountPaid += Number(this.inputValue);
                this.payType.push({ type: "cash", amount: this.inputValue })
                this.inputValue = "";
            } else {
                this.inputValue += key.toString();
            }
        },
        async purchase() {

            try {
                if (this.productInCart.length <= 0) {
                    return this.showSnackBar("אין מוצרים בעגלה", "red");
                }
                if (this.amountPaid < this.amountToPay) {
                    return this.showSnackBar("לאחר תשלום כל הסכום ניתן לבצע את הרכישה", "red");
                }
                this.purchaseInProgress = true;
                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ products: this.productInCart, customer: this.customer, cashierName: this.cashierName, payDetails: this.payType, totalPaid: this.amountPaid });

                let api = process.env.VUE_APP_BASE_URL + "/kupa/purchase_products";
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                const jsonObject = await res.json();
                if (res.status === 400 || res.status === 404) {
                    this.purchaseInProgress = false;
                    this.showSnackBar("שגיאה בעת הרכישה " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.showSnackBar("הרכישה בוצעה בהצלחה!", "green");
                    const payDetails = jsonObject;
                    setTimeout(() => {
                        this.printInvoice(payDetails);
                        this.$emit("purchased");

                    }, 700);
                }
            } catch (error) {
                this.purchaseInProgress = false;
                this.showSnackBar("Error check id: " + error, "red");
            }
        },
        printInvoice(payDetails) {
            const printContent = document.createElement('div');
            let ComponentClass = Vue.extend(InvoiceTemplate);
            let instance = new ComponentClass({
                propsData: { customer: this.customer, productInCart: this.productInCart, payDetails: payDetails }
            });
            instance.$mount(); // Mount the component instance to generate HTML
            // instance.$el.classList.add('print-page'); // Ensure the page break class is applied

            // Append the instance to a temporary container
            printContent.appendChild(instance.$el);

            // Open and configure the print window
            const printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
            printWindow.document.write('<html dir="rtl"><head><title></title></head><body>');
            printWindow.document.write('<style>.print-page { page-break-after: always; }</style>'); // Include the style directly
            printWindow.document.body.appendChild(printContent); // Append the container with all items
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            this.$nextTick(() => {
                printWindow.print(); // Invoke the print dialog
                //    printWindow.close(); // Consider removing this line or managing window closing differently.
            });
        },
        paySuccess(amountPaid, payDetails) {
            this.creditcardPaid = true;
            this.amountPaid += Number(amountPaid);
            this.payType.push(payDetails)
        },
        applyBalance() {
            // The total amount that needs to be paid.
            const amountToPay = this.amountToPay;
            // Amount already paid, if any.
            const amountPaid = this.amountPaid;
            // The balance available to the customer.
            const balance = this.customer.order_details.balance;

            // Calculate the remaining amount that needs to be covered.
            const remainingAmountToPay = amountToPay - amountPaid;

            // Determine the amount to be used from the balance. Do not exceed the remaining amount that needs to be paid.
            const amountFromBalance = Math.min(balance, remainingAmountToPay);

            // Update the amount paid by adding the amount taken from the balance.
            this.amountPaid += amountFromBalance;

            // Subtract the used amount from the customer's balance.
            this.customer.order_details.balance -= amountFromBalance;

            if (amountFromBalance && amountFromBalance > 0) {
                console.log(555555);
                console.log(this.inputValue)
                this.payType.push({ type: "balance", amount: amountFromBalance })
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        amountToPayInCC() {
            return this.amountToPay - this.amountPaid;
        }
    },
    created() {
        this.customer = JSON.parse(JSON.stringify(this.Customer));
    },
}
</script>
<style scoped>
@keyframes backgroundAnimation {
    0% {
        background-color: initial;
    }

    100% {
        background-color: green;
    }

    /* Or any other color you want when balance > 0 */
}

.animate-background {
    animation: backgroundAnimation 1.3s infinite alternate;
}
</style>
    