<template>
    <div style="margin-top: 30px; margin-bottom: 70px;">
        <div style="text-align: end; padding-left: 1%; margin-bottom: 20px;">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2" outlined medium fab color="indigo" @click="openEditDialog()">
                        <v-icon v-on="on" color="#3F51B5">
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </template>
                <span>עריכה</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn class="ma-2" outlined medium fab color="indigo" @click="openDeleteDialog()">
                        <v-icon v-on="on" color="#3F51B5">
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </template>
                <span>מחיקה</span>
            </v-tooltip>
        </div>
        <v-row style="padding-left: 1%; display: flex; justify-content: center;">
            <v-col cols='10' :lg='isTablesListExpanded ? 6 : 5'>
                <v-card style="padding: 2%;">
                    <h3 style="margin-bottom: 30px;">פרטי לקוח</h3>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">משפחה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.family }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">שם אבא</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.father_name }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">שם אמא</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.mother_name }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">ת.ז אבא</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.father_id }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">ת.ז אמא</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.mother_id }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">קהילה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.community }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">סטטוס</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.status ? 'פעיל' : 'מושבת' }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">מזהה פאוורלינק</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.powerLink_id }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">מצב משפחתי</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.mode }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">לקוח VIP</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.is_vip ? 'כן' : 'לא' }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="margin-left: 2%;">
                        <v-col cols='11' style="border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="2">
                                    <div>
                                        <span class="title-span">הערות</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.order_details.notes }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <h3 style="margin-bottom: 30px; margin-top: 50px;">דרכי התקשרות</h3>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">טלפון 1</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.phone1 }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">טלפון 2</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.phone2 }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">טלפון 3</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.phone3 }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">טלפון 4</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.phone4 }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">אימייל</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.email }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">רחוב</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.address }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">מספר בית</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.apartment }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">עיר</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.city }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: 20px;">
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">נוצר בתאריך</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.createdAt }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">עודכן בתאריך</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.updatedAt }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>


            <v-col cols='10' :lg='isTablesListExpanded ? 6 : 5'>
                <v-card style="padding: 2%;">
                    <h3 style="margin-bottom: 30px; ">פרטי הזמנה</h3>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">כמות זכאות להזמנה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.order_details.possible_quantity }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">כמות שהוזמנה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer_orders.length }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">כמות שנרכשה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ ordersPurchased }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">זכאי לרכישה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ ordersNotPurchased }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">יתרה בש"ח</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.order_details.balance }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <h3 style="margin-bottom: 30px; margin-top: 50px;">פרטי חלוקה</h3>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">נקודת חלוקה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.order_details.shop_station }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="display: flex; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">יום חלוקה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.order_details.day_to_come }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="title-span">שעת חלוקה</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ customer.order_details.hour_to_come }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <h3 style="margin-bottom: 30px; margin-top: 50px;">הזמנות</h3>
                    <v-btn small color="#3F51B5" dark @click="openAddOrderDialog()">
                        הוסף הזמנה
                    </v-btn>
                    <div style="height: 300px; overflow-y: auto;">
                        <v-data-table id="table1" :headers="ordersHeaders" :items="customer_orders" sort-by="index"
                            class="elevation-0" hide-default-footer>

                            <template v-slot:item.index="{ item, index }">
                                <tr>
                                    <td>{{ index + 1 }}</td>
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <v-row v-if="progressShow" class="my-5">
                                    <v-col class="d-flex justify-center">
                                        <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                                    </v-col>
                                </v-row>
                                <div class="ma-5">
                                    <span class="text-center"
                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                                        נתונים</span>
                                </div>
                            </template>
                            <template v-slot:item.actions="{ item, index }">
                                <v-tooltip bottom v-if="item.status !== 'נרכש'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#3F51B5" medium class="mr-2 me-3"
                                            @click="openEditOrderDialog(item, index)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                                <v-tooltip bottom v-if="item.status !== 'נרכש'">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="#3F51B5" medium class="me-3"
                                            @click="openDeleteOrderDialog(item, index)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </template>

                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :itemToEdit="editedItem" :formTitle="'ערוך לקוח'"
            @customerEdited="editedCustomerSaved"> </AddEditDialog>

        <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @customerDeleted="customerDeleted" />

        <AddEditOrderDialog v-model="editOrderDialog" v-if="editOrderDialog" :itemToEdit="editedOrderItem"
            :customerZeut="customer.father_id" :formTitle="formTitle" @orderEdited="editedOrderSaved"
            @orderAdded="newOrderSaved" />

        <AlerOrdertDialog v-model="alertOrderDialog" v-if="alertOrderDialog" :item="itemOrderToDelete"
            @orderDeleted="orderDeleted" />

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import AddEditDialog from '@/components/customers/dialogs/AddEditDialog'
import AddEditOrderDialog from '@/components/orders/dialogs/AddEditDialog'
import AlertDialog from '@/components/customers/dialogs/AlertDialog'
import AlerOrdertDialog from '@/components/orders/dialogs/AlertDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import Utils from "@/util/utils";
import { mapState, mapMutations } from 'vuex';

export default {
    props: {
        customerId: String,
    },
    components: {
        AddEditDialog,
        AlertDialog,
        AlerOrdertDialog,
        AddEditOrderDialog,
        SnackBar,
    },
    data: () => ({
        alertDialog: false,
        alertOrderDialog: false,
        addEditDialog: false,
        editOrderDialog: false,
        customer: {
            father_id: "",
            order_details: {
                possible_quantity: 0
            }
        },
        customer_orders: [],
        ordersHeaders: [
            { text: '#', value: "index", sortable: false },
            { text: 'ברקוד', value: 'barcode' },
            { text: 'מידה', value: 'size' },
            { text: 'בן/בת', value: 'gender' },
            { text: 'סוג', value: 'type' },
            { text: 'מחיר', value: 'price' },
            { text: 'סטטוס', value: 'status' },
            { text: 'פעולות', value: 'actions', sortable: false },
        ],
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        editedItem: {},
        editedOrderItem: {},
        itemToDelete: {},
        itemOrderToDelete: {},
        formTitle: "",
    }),
    computed: {
        ...mapState(['isTablesListExpanded']), // Get Vuex state

        ordersPurchased() {
            return this.customer_orders.filter(order => order.status === "נרכש").length;
        },
        ordersNotPurchased() {
            return this.customer_orders.filter(order => order.status !== "נרכש").length;
        },
    },
    methods: {
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getCustomerDetails() {
            try {
                let token = localStorage.getItem("token");
                const customerJSON = JSON.stringify({ customer_id: this.customerId });

                let api = process.env.VUE_APP_BASE_URL + "/customers/get_customer_details";
                const res = await fetch(api, ApiServices.requestOptions("POST", customerJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.customer = jsonObject;
                    console.log(this.customer);
                    this.formatDetails();
                }

            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get subscribes list: " + error, "red");
            }
        },
        async getCustomerOrders() {
            try {
                let token = localStorage.getItem("token");
                const customerJSON = JSON.stringify({ customer_id: this.customerId });

                let api = process.env.VUE_APP_BASE_URL + "/customers/get_customer_orders";
                const res = await fetch(api, ApiServices.requestOptions("POST", customerJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.customer_orders = jsonObject;
                    this.formatStatus();
                }

            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get subscribes list: " + error, "red");
            }
        },
        formatDetails() {
            // Directly format the nested 'order_details.day_to_come' property
            const formattedOrderDetails = {
                ...this.customer.order_details,
                day_to_come: this.customer.order_details.day_to_come ? Utils.getFormatDateWithoutHour(this.customer.order_details.day_to_come) : null,
            };

            // Update the customer object with the formatted 'order_details' and other properties
            this.customer = {
                ...this.customer,
                order_details: formattedOrderDetails, // Use the formatted nested object
                createdAt: Utils.getFormatDate2(this.customer.createdAt),
                updatedAt: Utils.getFormatDate2(this.customer.updatedAt),
            };
        },
        formatStatus() {
            this.customer_orders = this.customer_orders.map((order) => {
                return {
                    ...order,
                    status: order.status === "order_by_phone" ? "הוזמן טלפונית" : order.status === "order_manually" ? "הוזמן ידנית" : "נרכש",
                }
            });
        },
        openEditDialog() {
            this.editedItem = JSON.parse(JSON.stringify(this.customer));
            this.addEditDialog = true
        },

        openDeleteDialog() {
            this.itemToDelete = JSON.parse(JSON.stringify(this.customer));
            this.alertDialog = true
        },
        editedCustomerSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }

            console.log(item);

            Object.assign(this.customer, item);
        },
        customerDeleted(item, text, color) {
            if (item === "") {
                this.showSnackBar(text, color)
                return
            }
            this.customers = null;
            this.showSnackBar(text, color)
            setTimeout(() => {
                this.$emit("backToCustomers");
            }, 700);
        },
        openEditOrderDialog(item) {
            this.editedOrderItem = item;
            this.editOrderDialog = true
            this.formTitle = "ערוך הזמנה"
        },
        openAddOrderDialog() {
            this.editOrderDialog = true
            this.formTitle = "הוסף הזמנה"
        },

        openDeleteOrderDialog(item) {
            this.itemOrderToDelete = item;
            this.alertOrderDialog = true
        },
        newOrderSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            this.customer_orders.push(item);
        },
        editedOrderSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.customer_orders.findIndex(order => order._id === item._id);
            Object.assign(this.customer_orders[index], item);
        },
        orderDeleted(item, text, color) {
            if (item === "") {
                this.showSnackBar(text, color)
                return
            }
            this.customer.order_details.balance += Number(item.price)
            const index = this.customer_orders.findIndex(order => order._id === item._id);
            this.customer_orders.splice(index, 1);
            this.showSnackBar(text, color)
        },
    },
    created() {
        this.getCustomerDetails();
        this.getCustomerOrders();
    },
}
</script>
<style scoped>
.title-span {
    color: #808184;
}

.text-span {
    color: #272425;
    font-weight: 500;
}
</style>