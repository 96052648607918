<template>
  <div style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='10' xl='11'>
        <v-data-table id="table1" :headers="headers" :items="orders" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" @update:sort-by="sortBy => debouncedGetOrders()"
          @update:sort-desc="sortDesc => debouncedGetOrders()" class="elevation-4" :items-per-page="itemsPerPage"
          @update:items-per-page="onItemsPerPageChange" :page.sync="page" :server-items-length="totalOrders"
          @update:page="onPageChange"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

          <template v-slot:top>
            <div class="d-flex justify-center pt-3">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #5C6BC0;">הזמנות</span>
            </div>
            <div class="divider"></div>

            <v-card elevation="0">
              <v-card-title>
                <div class="d-flex flex-wrap pa-5 ">
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="openCreateDIalog()">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>הוסף חדש</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="downloadExcel()">
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="importDialog = true">
                          mdi-file-upload
                        </v-icon>
                      </template>
                      <span>ייבוא מאקסל</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="refreshDetails()">
                          mdi-refresh
                        </v-icon>
                      </template>
                      <span>רענן תוצאות</span>
                    </v-tooltip>
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details>
                </v-text-field> -->
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details clearable
                  @input="handleSearchInput">
                </v-text-field>
              </v-card-title>
            </v-card>
          </template>

          <template v-slot:item.index="{ index }">
            <tr>
              <!-- Calculate the correct index based on the current page and items per page -->
              <!-- Assuming page is 1-based, adjust calculation if your page numbering starts from 0 -->
              <td>{{ (page - 1) * itemsPerPage + index + 1 }}</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn color="#3F51B5" dark style="margin-bottom: 10px;" @click="debouncedGetOrders()">
              רענן
            </v-btn>
          </template>

          <template v-slot:item.family="{ item }">
            <span @click.stop="moveToCustomerPage(item)" style="cursor: pointer; color: blue;">
              {{ item.family ? item.family : 'חסר שם' }}
            </span>

          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom v-if="item.status !== 'נרכש'">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#3F51B5" medium class="mr-2 me-3" @click="openEditDialog(item, index)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>עריכה</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.status !== 'נרכש'">

              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#3F51B5" medium class="me-3" @click="openDeleteDialog(item, index)">
                  mdi-delete
                </v-icon>
              </template>
              <span>מחיקה</span>
            </v-tooltip>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      @orderAdded="newOrderSaved" @orderEdited="editedOrderSaved"> </AddEditDialog>

    <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @orderDeleted="orderDeleted">
    </AlertDialog>

    <ImportFromExcelDialog v-model="importDialog" v-if="importDialog" @ImportExcel="ImportExcel"
      @exportExampleExcel="donwloadExampleExcel"></ImportFromExcelDialog>

    <ImportFinishDialog v-model="importFinishDialog" v-if="importFinishDialog" :importedSuccess="importedSuccess"
      :importedFailed="importedFailed" @importedSuccessAdded="importedSuccessAdded"></ImportFinishDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <template>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
      </v-overlay>
    </template>
  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import TableToExcel from "@linways/table-to-excel";
import AddEditDialog from '@/components/orders/dialogs/AddEditDialog'
import AlertDialog from '@/components/orders/dialogs/AlertDialog'
import ImportFromExcelDialog from '@/components/orders/dialogs/ImportExcel'
import ImportFinishDialog from '@/components/orders/dialogs/ImportMessageDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import * as XLSX from 'xlsx/xlsx.mjs';
import Utils from "@/util/utils";
import _ from 'lodash'; // Import lodash or use another debounce utility

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar,
    ImportFromExcelDialog,
    ImportFinishDialog
  },
  data: () => ({
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'משפחה', value: 'family' },
      { text: 'ת.ז אבא', value: 'father_id' },
      { text: 'ת.ז אמא', value: 'mother_id' },
      { text: 'ברקוד', value: 'barcode' },
      { text: 'מידה', value: 'size' },
      { text: 'מחיר', value: 'price' },
      { text: 'בן/בת', value: 'gender' },
      { text: 'סוג', value: 'type' },
      { text: 'סטטוס', value: 'status' },
      { text: 'נוצר בתאריך', value: 'createdAt' },
      { text: 'עודכן בתאריך', value: 'updatedAt' },
      { text: 'פעולות', value: 'actions', sortable: false },
    ],
    importDialog: false,
    importFinishDialog: false,
    importedFailed: [],
    importedSuccess: [],
    alertDialog: false,
    addEditDialog: false,
    formTitle: "",
    orders: [],
    overlay: false,
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    email: "",
    search: "",
    itemsPerPage: 50, // default items per page
    page: 1, // Current page
    totalOrders: 0, // Total number of customers for pagination controls
    sortBy: "size",
    sortDesc: false, // default sort direction
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {

    async getOrders() {

      try {
        this.orders = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");
        const ordersJSON = JSON.stringify({ search: this.search, page: this.page, itemsPerPage: this.itemsPerPage, sortBy: this.sortBy, sortDesc: this.sortDesc });

        let api = process.env.VUE_APP_BASE_URL + "/orders/get_orders";
        const res = await fetch(api, ApiServices.requestOptions("POST", ordersJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.orders = jsonObject.orders;
          this.totalOrders = jsonObject.total;
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    onPageChange(newPage) {
      this.page = newPage;
      this.debouncedGetOrders();
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      // No need to call fetchCustomers() if you're using a watcher on itemsPerPage
    },
    formatDetails() {
      this.orders = this.orders.map((order) => {
        return {
          ...order,
          createdAt: Utils.getFormatDate2(order.createdAt),
          updatedAt: Utils.getFormatDate2(order.updatedAt),
          status: order.status === "order_by_phone" ? "הוזמן טלפונית" : order.status === "order_manually" ? "הוזמן ידנית" : "נרכש",
        }
      });
    },
    subscribesImportedSuccessMessage(text, color) {
      this.showSnackBar(text, color)
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async downloadExcel() {
      try {
        this.overlay = true;
        let token = localStorage.getItem("token");
        const body = JSON.stringify({ filter: this.search, sortBy: this.sortBy, sortDesc: this.sortDesc });
        let api = `${process.env.VUE_APP_BASE_URL}/orders/export_orders_to_csv`;
        const res = await fetch(api, ApiServices.requestOptions("POST", body, token));
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          // Handle successful response
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `orders.xlsx`; // Suggested file name
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      } catch (error) {
        this.showSnackBar("Error get items search list: " + error, "red");
      } finally {
        this.overlay = false;
      }
    },
    async donwloadExampleExcel() {

      const originalHeaders = JSON.parse(JSON.stringify(this.headers));

      this.headers = [
        { text: 'card_id', value: 'card_id' },
        { text: 'size', value: 'size' },
        { text: 'gender', value: 'gender' },
        { text: 'type', value: 'type' },
      ];

      this.$nextTick(() => {
        // Clone the table
        const table = document.getElementById('table1');
        const clonedTable = table.cloneNode(true);

        // Now the clonedTable only contains the desired header columns
        TableToExcel.convert(clonedTable, {
          name: 'exampleOrdersFile.xlsx',
          sheet: {
            name: 'Sheet 1',
          },
        });

        this.headers = originalHeaders;

      })
    },
    // Method to set items per page
    setItemsPerPage(value) {
      this.itemsPerPage = value;
    },
    refreshDetails() {
      this.getOrders();
    },
    openEditDialog(item, index) {
      this.editedItem = item;
      this.formTitle = "ערוך הזמנה"
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemToDelete = item
      this.formTitle = "האם אתה בטוח שברצונך למחוק את ההזמנה?"
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף הזמנה"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newOrderSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.orders.push(item);

    },
    editedOrderSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      const index = this.orders.findIndex(order => order._id === item._id);
      Object.assign(this.orders[index], item);
    },
    orderDeleted(item, text, color) {
      if (!item) {
        this.showSnackBar(text, color)
        return
      }
      const index = this.orders.findIndex(order => order._id === item._id);
      this.orders.splice(index, 1);
      this.showSnackBar(text, color)
    },
    ImportExcel(file) {

      this.importedSuccess = [];
      this.importedFailed = [];

      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          for (let i = 0; i < jsonData.length; i++) {
            const row = jsonData[i];
            const { card_id, size, gender, type } = row;

            // Valid order not empty
            if (!size || !card_id || !gender || !type) {
              console.log("error row is missing details");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            console.log(2222222);
            const regex = /^\d+$/; // Only numeric characters
            const isValidFormat1 = regex.test(card_id);
            if (!isValidFormat1) {
              console.log("error format card_id");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            const isValidFormat2 = regex.test(size);
            if (!isValidFormat2) {
              console.log("error format size");
              this.importedFailed.push(row)
              continue; // Skip to the next iteration if the format is invalid
            }

            const rowData = { card_id, size, gender, type };

            this.importedSuccess.push(rowData)
          }

          this.importFinishDialog = true;

        } catch (error) {
          this.showSnackBar('Error parsing Excel file: ' + error, "red");
        }
      };
      reader.onerror = (e) => {
        this.showSnackBar('Error reading file: ' + e.target.error, "red");
      };
      reader.readAsArrayBuffer(file);

    },
    importedSuccessAdded() {
      this.showSnackBar('ההזמנות נוספו בהצלחה!', "green");
      this.getOrders();
    },
    moveToCustomerPage(item) {
      this.$emit("openCustomerDetails", item.customer_id)
    },
    handleSearchInput(value) {
      if (value) {
        this.debouncedGetSearchOrders()
      } else {
        this.getOrders()
      }
    },
  },
  watch: {
    itemsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrders();
      }
    },
  },
  mounted() {
    this.getOrders();

    // Create a debounced version of getData to avoid multiple rapid calls
    this.debouncedGetSearchOrders = _.debounce(this.getOrders, 700);
    this.debouncedGetOrders = _.debounce(this.getOrders, 300);

  },
}
</script>

<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}
</style>